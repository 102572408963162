<template>
  <v-form @submit.stop.prevent="checkForm()" ref="form" class="mt-5">
    <v-row
      v-if="user_prop.registerUrl && user_prop.isTech"
      align="center"
      class="ml-3"
    >
      <h5>
        <span class="bold">
          {{ `${$t("alpha_register")}:` }}
        </span>
        <span>
          {{ `${user_prop.registerUrl}` }}
        </span>
      </h5>
      <v-btn
        x-small
        icon
        class="ml-3"
        @click="gs.copy_url_to_clipboard(user_prop.registerUrl)"
      >
        <v-icon color="primary">mdi-content-copy</v-icon>
      </v-btn>
    </v-row>
    <v-row class="ml-3 mt-5">
      <v-text-field v-model="user.name" label="Empresa parceira" />
    </v-row>

    <!-- cadastro alpha, links em (pt e en) -->
    <div
      v-if="
        user_prop.alphaRegisterUrlsLang &&
        user_prop.alphaRegisterUrlsLang.length > 0 &&
        !user_prop.isTech
      "
    >
      <v-row
        align="center"
        class="ml-0 last-mt-4"
        v-for="(alphaUrl, i) in user_prop.alphaRegisterUrlsLang"
        :key="i"
      >
        <div v-if="alphaUrl" class="alphaRegisterUrlsLangContainer">
          <h5>
            <span class="bold">{{
              `${$t("alpha_register")}[${getAlphaRegisterUrlLang(alphaUrl)}]:`
            }}</span>
            <span>
              {{ `${alphaUrl}` }}
            </span>
          </h5>
          <v-btn
            x-small
            icon
            class="ml-3"
            @click="gs.copy_url_to_clipboard(alphaUrl)"
          >
            <v-icon color="primary">mdi-content-copy</v-icon>
          </v-btn>
        </div>
      </v-row>
    </div>

    <!-- cadastro do assessor -->
    <v-row
      v-if="user_prop.bankerRegisterUrl && !user_prop.isTech"
      align="center"
      class="ml-0 mt-5"
    >
      <h5>
        <span class="bold">{{ `${$t("banker_register")}:` }}</span>
        <span>
          {{ `${user_prop.bankerRegisterUrl}` }}
        </span>
      </h5>
      <v-btn
        x-small
        icon
        class="ml-3"
        @click="gs.copy_url_to_clipboard(user_prop.bankerRegisterUrl)"
      >
        <v-icon color="primary">mdi-content-copy</v-icon>
      </v-btn>
    </v-row>

    <v-row v-if="is_admin" align="center" class="ml-0">
      <v-col sm="12" md="6">
        <p class="colorpickerTitle">{{ $t("comanager_title") }}</p>
        <v-checkbox
          class="field"
          color="primary"
          outlined
          dense
          v-model="IsCoManager"
        >
          <template v-slot:label>
            <span style="color: var(--dark)">{{ $t("comanager") }}</span>
          </template>
        </v-checkbox>
        <p class="colorpickerTitle">{{ $tc("partner", 1) }}</p>
        <v-checkbox
          class="field"
          color="primary"
          outlined
          dense
          v-model="IsTech"
        >
          <template v-slot:label>
            <span style="color: var(--dark)">{{
              $t("partner_dxa_tech_title")
            }}</span>
          </template>
        </v-checkbox>
        <p class="colorpickerTitle">{{ $t("market_app_label") }}</p>
        <v-checkbox
          class="field"
          color="primary"
          outlined
          dense
          v-model="AccessMarket"
        >
          <template v-slot:label>
            <span style="color: var(--dark)">{{
              $t("access_market_app")
            }}</span>
          </template>
        </v-checkbox>
        <v-select
          v-if="IsCoManager"
          :disabled="!IsCoManager"
          v-model="selectedCompanies"
          :items="companies"
          :label="$t('companies')"
          multiple
          outlined
          dense
          item-text="Name"
          item-value="CompanyId"
          ><template v-slot:label>
            <span style="color: var(--dark)">{{
              $t("select_companies_comanager")
            }}</span>
          </template>
          ></v-select
        >
      </v-col>
    </v-row>

    <v-row align="center" class="mx-auto">
      <v-col sm="12" md="3">
        <p class="colorpickerTitle">{{ $t("company_color") }}</p>
        <v-color-picker
          v-model="picker"
          mode="hexa"
          elevation="5"
          @change="showColor()"
        ></v-color-picker>
      </v-col>

      <v-col sm="12" md="9">
        <v-row align="center">
          <v-col cols="6" md="6">
            <p class="colorpickerTitle">{{ $t("logo") }}</p>
            <input
              class="ml-0"
              id="imageLogoInput"
              name="imageLogoInput"
              ref="imageLogoInput"
              type="file"
              @change="setImageLogo"
            />
            <p class="alert-size-image-message mt-1">
              {{ $t("alert_size_image") }}
            </p>
          </v-col>
          <v-col
            class="pb-0 ma-1"
            cols="12"
            md="5"
            style="display: flex; align-items: end"
          >
            <v-checkbox v-model="isCropSelected" hide-details="" /><span>{{
              $t("crop")
            }}</span>
            <v-tooltip left max-width="300px" style="align-items: end">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="white"
                  elevation="0"
                  icon
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="primary">mdi-help-circle</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("crop_description") }}</span>
            </v-tooltip>
          </v-col>
          <v-col v-if="isCropSelected == true && user.fileString">
            <cropper
              @change="change"
              style="max-width: 483px; height: 210px"
              :src="showPhoto()"
              class="cropper"
            >
            </cropper>
          </v-col>
          <v-col v-else-if="user.companyLogoPath || user.fileString">
            <img id="selected-company-logo" :src="showPhoto()" />
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="12">
        <v-text-field
          readonly
          v-model="user.showcaseLink"
          :label="$t('partner_url')"
        />
      </v-col>
      <v-col sm="12">
        <v-text-field
          v-model="user.partnerAuthToken"
          readonly
          :label="$t('partner_auth_token')"
        />
      </v-col>
      <v-col sm="12">
        <v-text-field
          v-model="user.updateWebhook"
          :label="$t('update_webhook')"
        />
      </v-col>
    </v-row>
    <v-card-actions class="pl-0 dxa_modal_actions">
      <v-btn
        :disabled="!imageSizeIsValid"
        class="dxa_modal_btnSuccess"
        color="primary"
        min-width="100"
        :loading="loading"
        type="submit"
        >{{ $t("save") }}</v-btn
      >
      <v-btn
        class="dxa_modal_btnError"
        color="secondary"
        min-width="100"
        @click="$router.go(-1)"
        >{{ $t("go_back") }}</v-btn
      >
    </v-card-actions>
  </v-form>
</template>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}

.last-mt-4:last-child {
  margin-top: 16px;
}

.colorpickerTitle {
  margin-bottom: 7px;
  font-weight: 500;
  color: var(--primary) !important;
}

.colorpickerArea {
  font-weight: 500;
  color: var(--primary) !important;
  margin-bottom: 15px;
}

#selected-company-logo {
  width: auto;
  height: auto;
  max-width: 460px;
  max-height: 260px;
}

.alert-size-image-message {
  font-size: 14px;
  font-weight: 400;
  font-family: "Source Sans Pro";
  color: var(--dark);
}

.alphaRegisterUrlsLangContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { getAlphaRegisterUrlLang } from "@/shared/helpers/alphaRegisterHelpers";

export default {
  name: "ManageUserPartner",
  components: {
    Cropper,
  },

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    isCropSelected: false,
    loading: false,
    picker: null,
    user: {},
    imageToCropCanvas: null,
    is_admin: false,
    selectedCompanies: [],
    companies: null,
    IsCoManager: false,
    IsTech: false,
    AccessMarket: false,
    imageSizeIsValid: true,
    userTypeEnum: UserTypeEnum,
    getAlphaRegisterUrlLang,
  }),

  computed: {
    required() {
      return (value) => !!value || this.$t("required");
    },
    min_valid() {
      return (v) => v.length >= 8 || this.$t("min_char");
    },
    special_valid() {
      return (v) =>
        !/[*|\":<>[\]{}`\\()';&$]/.test(v) || this.$t("special_char");
    },
    accent_valid() {
      return (v) => !/[À-ÖØ-öø-ÿ]/.test(v) || this.$t("accent_char");
    },
  },
  props: {
    user_prop: Object,
  },

  async created() {
    this.alert_msg = null;
    if (this.user_prop != null) {
      this.user = this.user_prop;
      this.picker = this.user.companyColor;
    }
    var local = JSON.parse(localStorage.getItem("user"));
    this.is_admin = local.type == 1;
    if (this.is_admin) {
      await this.get_companies();
      this.IsCoManager = this.user.isCoManager;
      this.IsTech = this.user.isTech;
      this.AccessMarket = this.user.accessMarket;
      this.selectedCompanies = this.user.partnerCompanies;
    }
  },

  methods: {
    cropSelected() {
      this.isCropSelected = !this.isCropSelected;
    },
    change({ coordinates, image, canvas }) {
      if (canvas != null || canvas != undefined)
        this.imageToCropCanvas = canvas.toDataURL();
      this.result = {
        coordinates,
        image,
      };
      this.cropImage();
    },
    cropImage() {
      this.$emit("save", this.imageToCropCanvas);
    },

    showPhoto() {
      if (this.user.fileString) {
        return this.user.fileString;
      } else {
        return this.user.companyLogoPath;
      }
    },
    email_error_msg: function () {
      return this.$t("format_invalid");
    },
    checkForm: function () {
      if (this.loading) {
        return;
      }
      this.loading = true;
      var result = this.$refs.form.validate();
      if (result) {
        this.submit();
      } else {
        this.loading = false;
      }
    },

    setImageLogo(event) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        this.user.fileString = reader.result;
        this.user.fileExtension = this.user.fileString
          .split("/")[1]
          .split(";")[0];
      };

      this.validatingImageSize(event)
        .then(() => {
          this.imageSizeIsValid = true;
          this.$forceUpdate();
        })
        .catch(() => {
          this.imageSizeIsValid = false;
          this.$toast.error(this.$t("alert_error_size_image"));
        });
    },
    async validatingImageSize(event) {
      this.imageSizeIsValid = true;

      return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);

        reader.onload = function (e) {
          let image = new Image();

          image.src = e.target.result;
          image.onload = function () {
            let height = this.height;
            let width = this.width;

            if (height === 50) {
              resolve();
            } else {
              reject();
            }
          };
        };
      });
    },
    async get_companies() {
      await this.apiService
        .getRequest(`company/list`)
        .then((resp) => {
          this.companies = JSON.parse(resp.message);
        })
        .catch((error) => {});
    },

    submit: async function () {
      this.error = null;
      var self = this;
      var client = new ApiService();

      if (this.user.type == this.userTypeEnum.Partner) {
        if (this.picker.hex) {
          this.user.companyColor = this.picker.hex;
        } else if (this.picker) {
          this.user.companyColor = this.picker;
        }
      }

      if (this.is_admin) {
        this.user.isCoManager = this.IsCoManager;
        this.user.partnerCompanies = this.selectedCompanies;
        this.user.isTech = this.IsTech;
        this.user.AccessMarket = this.AccessMarket;
      }

      if (this.isCropSelected) {
        this.user.fileString = this.imageToCropCanvas;
      }
      this.isCropSelected = false;
      await client
        .putRequest("partnerb2b", this.user)
        .then((result) => {
          if (result.companyLogoPath) {
            self.user.companyLogoPath = result.companyLogoPath;
          }
          self.$emit("saved", self.user);
          self.$emit("alert", true, self.$t("saved"));
          self.loading = false;
        })
        .catch((err) => {
          if (err.status === 400) {
            if (err.body.content.name && err.body.content.name.length > 0) {
              const partnerB2bAlreadyExists = err.body.content.name.find(
                (item) => item === "partnerb2b_name_already_exists"
              );

              if (partnerB2bAlreadyExists) {
                self.$emit(
                  "alert",
                  false,
                  self.$t("company_partner_already_exist")
                );
              }
            }
          }
          self.loading = false;
        });
    },
  },
};
</script>
